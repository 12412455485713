<template>
<b-card>
    <b-row style="padding-bottom: 20px;">
        <b-col cols="12" align-self="end" style="text-align: end;">
            <b-button v-b-modal.modal-scrollable variant="primary">
                Submit Test Fit
            </b-button>
        </b-col>
    </b-row>
    <b-modal size="lg" id="modal-scrollable" scrollable title="Test Fit Submission">
        <upload-modal />
        <template #modal-footer="{ ok }">
            <b-button size="sm" variant="success" @click="ok()" style="display:none">
                ok
            </b-button>
        </template>
    </b-modal>
    <b-table :busy="isBusy" responsive="sm" :fields="fields" :items="quotes" @row-clicked="clicked">
        <template #table-busy>
            <div class="text-center text-primary my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong> Loading...</strong>
            </div>
        </template>
        <template #cell(id)="data">
            <!-- `data.value` is the value after formatted by the Formatter -->
            <a :href="`/quotes/${data.value}`">{{ data.value }}</a>
        </template>
        <template #cell(sqft)="data">
            {{Number((data.value)).toLocaleString(undefined, {minimumFractionDigits: 0}) +  ' sqft'}}
        </template>
        
    </b-table>
</b-card>
</template>

<script>
import {
    BCard,
    BCardText,
    BTable,
    BButton,
    BModal,
    VBModal,
    BRow,
    BCol,
    BSpinner
} from 'bootstrap-vue'
import UploadModal from './UploadModal.vue'

export default {
    components: {
        BCard,
        BCardText,
        BTable,
        BButton,
        BModal,
        UploadModal,
        BRow,
        BCol,
        BSpinner
    },
    directives: {
        'b-modal': VBModal,
    },
    data() {
        return {
            quotes: [],
            isBusy: true,
            fields: [{
                    key: 'id',
                    label: 'ID',
                    sortable: true
                },
                {
                    key: 'project.name',
                    label: 'PROJECT',
                    sortable: false
                },
                {
                    key: 'client',
                    label: 'CLIENT',
                    sortable: true,
                },
                {
                    key: 'sqft',
                    label: 'RENTABLE SQFT',
                    sortable: true,
                },
                // {
                //     key: 'status',
                //     label: 'STATUS',
                //     sortable: true,
                // }
            ]
        }
    },
    methods: {
        getQuotes() {
            this.$http.get('/quotes')
                .then(response => {
                    this.quotes = response.data;
                    this.isBusy = false;
                })
                .catch(error => {
                    this.showToast('danger', error.response.data.message)
                });
        },
        clicked(item){
            const id = item.id;
            this.$router.push(`/quotes/${id}`)
        }
        

    },
    beforeMount() {
        this.getQuotes();
    }
}
</script>

<style>

</style>
